import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import paymentApi from '@/api/common/payment';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import CaretTop from "../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../views/components/Icon/CaretBottom.vue";
import UserBasicInformation from "../../views/components/column/UserBasicInformation.vue";
const queryString = require('query-string');

export default {

    components: {
        UserBasicInformation,
        CaretTop,
        CaretBottom,
    },

    extends: baseComponent,

    data() {
        return {
            listData: {},
            pagination: {
                querySearch: '',
                pageIndex: 1,
                pageSize: 20,
                startingAfter: null,
                endingBefore: null,
            },
            action: '',
            actionList: [],
            paymentIntentStatusList: [
                {
                    status: 'succeeded',
                    name: 'Succeeded',
                    icon: 'el-icon-check',
                    color: '#0e6245',
                    iconColor: '#09825d',
                    backgroundColor: '#cbf4c9',
                },
                {
                    status: 'canceled',
                    name: 'Canceled',
                    icon: 'el-icon-close',
                    color: '#4f566b',
                    iconColor: '#697386',
                    backgroundColor: '#e3e8ee',
                },
                {
                    status: 'requires_payment_method',
                    name: 'Requires payment method',
                    icon: null,
                    color: null,
                    iconColor: null,
                    backgroundColor: null,
                },
            ],
            outcomeTypeList: [
                {
                    status: 'issuer_declined',
                    name: 'Failed',
                    icon: 'el-icon-close',
                    color: '#a41c4e',
                    iconColor: '#cd3d64',
                    backgroundColor: '#fde2dd',
                },
                {
                    status: 'blocked',
                    name: 'Blocked',
                    icon: 'el-icon-circle-close',
                    color: '#a41c4e',
                    iconColor: '#cd3d64',
                    backgroundColor: '#fde2dd',
                },
            ]
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {
        this.$userLogStatus.forEach(element => {
            if (this.actionList.find(x => x.label === element.name) !== undefined) {
                this.actionList.find(x => x.label === element.name).value = `${this.actionList.find(x => x.label === element.name).value},${element.status}`
            } else {
                this.actionList.push({
                    label: element.name,
                    value: element.status.toString(),
                    icon: element.icon,
                })
            }
        });
    },

    mounted() {
        this.getData(this.pagination);
    },

    methods: {

        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData(this.pagination);
        },

        // Pagination page change
        previous() {
            this.pagination.pageIndex = this.pagination.pageIndex - 1;
            this.pagination.endingBefore = this.listData[0].id;
            this.pagination.startingAfter = null;
            this.getData(this.pagination);
        },

        next() {
            this.pagination.pageIndex = this.pagination.pageIndex + 1;
            this.pagination.endingBefore = null;
            this.pagination.startingAfter = this.listData[this.pagination.pageSize - 1].id;
            this.getData(this.pagination);
        },

        //Context menu action
        contextMenuAction(command) {
            switch (command.type) {
                case "user-history-add-restricted-list":
                    EventBus.$emit("openPopup", command);
                    break;
                case "user-report-user":
                    command.username = command.record.username;
                    EventBus.$emit("openPopup", command);
                    break;
                case "user-report-ip":
                    command.ip = command.record.ip;
                    EventBus.$emit("openPopup", command);
                    break;
            }
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.feedStatusLoading(0);
            paymentApi.getStripeHistory(queryString.stringify(request))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = responseData.pagingItem;
                        this.listData = responseData.data;
                        console.log("getData -> this.listData", JSON.stringify(this.listData))
                        // this.listData.forEach(element => {
                        //     console.log("log---", element)
                        // });
                        // this.pagination.orderBy = pagingItem.orderBy;
                        // this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        // this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                // setTimeout(() => {
                                //     location.href = "/login";
                                // }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },
    }
}