import baseComponent from "@/scripts/baseComponent";
import { mapState } from "vuex";
import usersApi from '@/api/common/users';
import { API_CALL_STATUS } from "@/constant/config";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBoxWithoutLoading from "@/components/SkeletonBoxWithoutLoading";
const queryString = require('query-string');
export default {

    extends: baseComponent,

    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        }),
    },
    props: {
        customerId: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            element: {
                totalRecharge: "...",
                gpuHubAccountBalance: "...",
                gpuHubAccountExtraBalance: "...",
                totalImage: "...",
                imageThreshold: "...",
                totalStorage: "...",
                storageThreshold: "...",
            }
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },
    mounted() {
        this.getData();
    },
    methods: {

        // Get data
        getData() {
            usersApi.basicUserInformationViewByStripeCustomerId(queryString.stringify({ customerId: this.customerId }))
                .then((res) => {
                    try {
                        this.element = res.data;

                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        }
    }
}