/* eslint-disable */
import { paymentServiceConfig, EXECUTE_RESULT } from "@/constant/config";
import axios from "axios";
import moment from "moment";
export default {

  // Get Stripe history
  getStripeHistory: function (request) {
    return new Promise(function (resolve, reject) {
      axios({
        method: "GET",
        url: `${paymentServiceConfig.getStripeHistory}?${request}`,
        withCredentials: true,
      })
        .then(res => {
          try {
            let resTemp = res.toJSON();
            if (resTemp.name === 'Error') {
              reject(resTemp);
            }
          } catch {
            let response = res.data;
            if (response.result === EXECUTE_RESULT.SUCCESS) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    })
  },
}